.Connect .ui.form .field {
  margin: 0 0 var(--spacing);
}

.Connect .ui.input {
  width: 100%;
}

.Connect .ui.form .pluggy.field {
  /* necessary to prevent from "jumping" in ios */
  height: calc(var(--spacing) * 3.5) !important;
  margin-bottom: var(--spacing);
}

.Connect .pluggy.field:first-of-type {
  margin-top: calc(var(--spacing) * 0.25);
}

.Connect .form-fields .pluggy.ui.input {
  height: calc(var(--spacing) * 2.5);
}

/* label default */
.Connect .form-fields .pluggy.ui.labeled.input .label.floating-label {
  font-size: var(--text-font-size-s);

  left: var(--spacing);
}

/* label focus/filled */
.Connect .form-fields .pluggy.ui.labeled.input.focus .label.floating-label,
.Connect .form-fields .pluggy.ui.labeled.input.filled .label.floating-label,
.Connect
  .form-fields
  .pluggy.ui.labeled.input
  input:focus
  ~ .label.floating-label {
  top: calc(var(--spacing) * -0.6);
  font-size: var(--text-font-size-xs);
  padding: calc(var(--spacing) * 0.25);
  left: calc(var(--spacing) * 0.75);
}

/* input */
.Connect .pluggy.field .pluggy.ui.input input {
  font-size: var(--text-font-size-s);

  padding: 0 var(--spacing);
  /* note: using !important to override !importants from the library */
  border-radius: calc(var(--spacing) * 0.25) !important;
  border-width: calc(var(--spacing) * 0.0625);
}

/* errors */
.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.labeled.input
  ~ .text-container {
  min-height: calc(var(--spacing) * 0.75);
  margin-top: calc(var(--spacing) * 0.25);
  line-height: calc(var(--text-font-size-xs) * 1.25);
  font-size: var(--text-font-size-xs);
}

/* errors */
.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.labeled.input
  ~ .text-container
  .InputIconError {
  width: var(--spacing);
  height: var(--spacing);
  min-width: var(--spacing);
  min-height: var(--spacing);
  margin-left: calc(var(--spacing) * 0.1563);
  margin-top: calc(var(--spacing) * 0.125);
}

.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.labeled.input
  ~ .text-container
  .InputIconError
  svg {
  width: calc(var(--spacing) * 0.875);
  height: calc(var(--spacing) * 0.875);
  min-width: calc(var(--spacing) * 0.875);
  min-height: calc(var(--spacing) * 0.875);
}

.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.labeled.input
  ~ .text-container
  .error-message {
  margin-left: calc(var(--spacing) * 1.35);
}

/* optional message */

.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.labeled.input
  ~ .optional-message {
  font-size: var(--text-font-size-xs);
}

/* icon */
.Connect .form-fields > .pluggy.field .pluggy.ui.input .icon-password-wrapper {
  right: calc(var(--spacing) * 0.8125);
}

.Connect
  .form-fields
  > .pluggy.field
  .pluggy.ui.input
  .icon-password-wrapper
  svg {
  width: calc(var(--spacing) * 1.25);
  height: calc(var(--spacing) * 1.25);
  min-width: calc(var(--spacing) * 1.25);
  min-height: calc(var(--spacing) * 1.25);
}
