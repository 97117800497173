.AilosDeviceConnectionLimitReachedError {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AilosDeviceConnectionLimitReachedError .Title {
  margin-bottom: 0;
}

.AilosDeviceConnectionLimitReachedError
  .pluggy.connector-header
  .connector-logo {
  margin-top: var(--spacing);
  margin-bottom: calc(var(--spacing) * 1.25);
}

.AilosDeviceConnectionLimitReachedError .pluggy.alert.error {
  margin-top: 0;
  margin-bottom: calc(var(--spacing) * 1.25);
}

.AilosDeviceConnectionLimitReachedError
  .action-group
  > .pluggy.ui.button:first-of-type {
  margin-bottom: calc(var(--spacing) * 0.75);
}
