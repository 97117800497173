.CaixaDeviceUnauthorizedStatus {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.CaixaDeviceUnauthorizedStatus strong {
  font-weight: 500;
}

.CaixaDeviceUnauthorizedStatus .device-authorization-message-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.CaixaDeviceUnauthorizedStatus .pluggy.alert.warning {
  margin-bottom: 0;
  margin-top: var(--spacing);
}

.CaixaDeviceUnauthorizedStatus .device-authorization-message-container .title {
  margin-bottom: 0;
  font-size: calc(var(--text-font-size));
  line-height: calc(var(--text-font-size) * 1.375);
  font-weight: 500;
}

.CaixaDeviceUnauthorizedStatus .pluggy.alert {
  margin: 0 0 var(--spacing) 0;
}

.CaixaDeviceUnauthorizedStatus .device-authorization-message-container p {
  line-height: var(--line-height);
  color: var(--grey-900);
}

.CaixaDeviceUnauthorizedStatus .subtitle {
  font-weight: 500;
  color: var(--text);
  font-size: calc(var(--text-font-size) * 0.85);
  margin: 0 0 calc(var(--spacing) * 0.75) 0;
}

.CaixaDeviceUnauthorizedStatus .device-authorization-message {
  margin-top: var(--spacing);
}

.CaixaDeviceUnauthorizedStatus .device-authorization-warning {
  display: flex;
  margin-top: auto;
  margin-bottom: calc(var(--spacing) * 0.25);
  font-size: var(--text-font-size-xs);
  color: var(--grey-600);
}

.CaixaDeviceUnauthorizedStatus .device-authorization-warning > span {
  margin-left: calc(var(--spacing) * 0.5);
  line-height: calc(var(--text-font-size));
}

.CaixaDeviceUnauthorizedStatus .pluggy.ui.button.link.help-link {
  margin-top: var(--spacing);
  display: flex;
  justify-content: flex-start;
}

.CaixaDeviceUnauthorizedStatus .action-container .pluggy.ui.button .content {
  font-size: var(--text-font-size);
}

.CaixaDeviceUnauthorizedStatus .action-container .pluggy.ui.button {
  /* necessary to fit large text */
  padding: calc(var(--spacing) * 0.25) 0;
}

.CaixaDeviceUnauthorizedStatus .need-help-section {
  margin-top: calc(var(--spacing) * 0.5);
}

.CaixaDeviceUnauthorizedStatus .need-help-section .need-help-title {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.CaixaDeviceUnauthorizedStatus .need-help-section .link.help-link {
  display: block;
  margin-bottom: calc(var(--spacing) * 0.25);
}
