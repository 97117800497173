.PersonalSafraDeviceAuthorization {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.PersonalSafraDeviceAuthorization .Title {
  margin: 0;
}

.PersonalSafraDeviceAuthorization .subtitle {
  font-weight: 500;
}

.PersonalSafraDeviceAuthorization .content-container .content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 0.75);
}

.PersonalSafraDeviceAuthorization .content-container .content br {
  display: block;
  content: '';
  margin-top: var(--spacing);
}
