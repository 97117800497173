.OpenFinanceAuthNotGaranted {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OpenFinanceAuthNotGaranted .actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
