.Connect .ConnectHeader {
  margin-top: 0;
  margin-bottom: 0;

  flex-shrink: 1;
  width: 100%;
}

.Connect .ConnectHeader.ui.header {
  margin-top: 0;
  margin-bottom: 0;
}

.Connect .ConnectHeader .header-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Connect .ConnectHeader.collapsed {
  flex-basis: auto;
}

.Connect .ConnectHeader .header-background-icons {
  position: absolute;
  max-width: 100%;
  /* prevent Semantic UI <img> positioning inside .ui.header */
  z-index: 1;
  width: unset;
  height: unset;
}

.Connect .ConnectHeader.welcome .header-column {
  /* Limit header section to 23.5% of the screen height, to prevent logo
    image from taking too much space and  make space for the rest of components.
    Note: value tested manually in iPhone 5/SE screen (568px height)
    TODO: improve with proper calculated values with Agus */
  max-height: 23.5vh;

  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.Connect .ConnectHeader .header-logo {
  margin-top: calc(var(--spacing) * 4.75);
  margin-bottom: calc(var(--spacing) * 1.65);
  max-width: calc(var(--spacing) * 8.4375);
  max-height: calc(var(--spacing) * 1.875);
}

.ConnectHeader .ConnectHeader .header-logo.pluggy-logo {
  width: calc(var(--spacing) * 3.43);
  height: calc(var(--spacing) * 2.31);
}

.Connect .ConnectHeader:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom-right-radius: calc(var(--spacing) * 1.25);
  border-bottom-left-radius: calc(var(--spacing) * 1.25);
  height: 100%;
  z-index: -1;
}

.Connect .ConnectHeader .header-background-icons-collapsed {
  width: 100%;
  height: calc(var(--spacing) * 3.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Connect .ConnectHeader .header-background-icons-collapsed > *:first-child {
  max-height: calc(var(--spacing) * 1.25);
  max-width: calc(var(--spacing) * 5.625);
}

.Connect .ConnectHeader.collapsed:after {
  /* reduce backdrop size */
  height: calc(var(--spacing) * 4.063);
}

.Connect .ConnectHeader .content {
  text-align: center;
}

.Connect .ConnectHeader .connector-logo {
  width: 100%;
  margin-bottom: calc(var(--spacing) * 1.5);
}

.Connect .ConnectHeader .connector-logo .image {
  max-height: calc(var(--spacing) * 2.31);
}

.Connect .ConnectHeader.collapsed .image {
  top: 20%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 30%;
}

/* Missing bank */
.ConnectHeader.collapsed.missing-bank-page {
  margin-bottom: 0;
}
