.Connect .pluggy.alert {
  margin: var(--spacing) 0;
  padding: 0 calc(var(--spacing) * 0.5) 0 calc(var(--spacing) * 0.3125);
  border-radius: calc(var(--spacing) * 0.25);
}

.Connect .pluggy.alert .indicator {
  min-width: calc(var(--spacing) * 0.25);

  border-radius: calc(var(--spacing) * 0.25) 0 0 calc(var(--spacing) * 0.25);
}

.Connect .pluggy.alert .text {
  margin: calc(var(--spacing) * 0.5) 0 calc(var(--spacing) * 0.5)
    calc(var(--spacing) * 0.5);

  font-size: var(--text-font-size-xs);
  line-height: calc(var(--text-font-size-xs) * 1.16);
}

/* medium size */

.Connect .pluggy.alert.medium {
  padding-left: var(--spacing);
  padding-right: calc(var(--spacing) * 0.625);
}

.Connect .pluggy.alert.medium .text {
  margin-left: calc(var(--spacing) * 0.75);
  font-size: var(--text-font-size-s);
  line-height: calc(var(--text-font-size-s) * 1.16);
}

/* icon */

.Connect .pluggy.alert.medium .icon {
  display: block;
  min-width: var(--spacing);
  min-height: var(--spacing);
}
