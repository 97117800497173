.content .ConnectSuccess {
  flex-basis: calc(var(--spacing) * 28.75);
}

.Connect .content .connect-status {
  position: relative;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: var(--spacing);
}

.Connect
  .connect-status
  .pluggy.connector-header
  .pluggy.connector-row
  .connector-logo {
  margin: calc(var(--spacing) * 1.25);
  margin-left: 0;
  width: var(--spacing-x2);
  min-width: var(--spacing-x2);
  height: var(--spacing-x2);
  min-height: var(--spacing-x2);
}

.Connect .connect-status .pluggy.connector-header .connector-row {
  margin: 0 var(--spacing);
}

.Connect .connect-status .pluggy.connector-header .pluggy.connector-row label {
  font-size: var(--text-font-size-s);
}

.Connect .content .connect-status .ui.grid {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0;
  padding-right: var(--spacing);
  padding-left: var(--spacing);
}

.Connect .content .connect-status .ui.grid > .row {
  flex-wrap: nowrap;
  flex-basis: calc(var(--spacing) * 2.5);
  padding-top: 0;
  padding-bottom: 0;
}

.connect-status .progress-bar,
.connect-status .action-container {
  width: 100%;
  left: 0;
}

.connect-status .progress-bar {
  padding-right: var(--spacing);
  padding-left: var(--spacing);
}

.connect-status .progress-bar .label {
  color: var(--text-progress-bar-label);
}

.connect-status .progress-bar .ui.progress {
  /* Add progress animation */
  transition: width 2s ease-in-out;
}
.connect-status .progress-bar .ui.progress {
  background-color: var(--progress-bar-background);
  margin-bottom: calc(var(--spacing) * 0.75);
}

.connect-status .progress-bar .ui.progress > .label {
  color: var(--text);
}

.connect-status .progress-bar .label {
  /* note: using important to override semantic values */
  margin-top: calc(var(--spacing) * 0.5) !important;
  font-size: var(--text-font-size-xs) !important;
  font-weight: 500 !important;
}

.connect-status .StepSlowMessage {
  margin: auto var(--spacing) calc(var(--spacing) * 1.25)
    calc(var(--spacing) * 1.125);
}

.Connect .connect-status .progress-bar .header.progress-value {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: calc(var(--text-font-size) * 1.5);
  line-height: calc(var(--spacing) * 1.125);
  text-align: center;
  margin-top: calc(var(--spacing) * 0.75);
}

.Connect .connect-status .action-container {
  bottom: calc(var(--spacing) * 1.5);
}

.Connect .connect-status .action-container > button {
  height: calc(var(--spacing) * 2.875);
}

.connect-status:not(.completed) .action-container {
  visibility: hidden;
}

.connect-status .open-finance-permission-management {
  width: 100%;
  padding: 0 var(--spacing);

  font-size: var(--text-font-size-xs);
  line-height: calc(var(--text-font-size) * 1.2);
  margin: var(--spacing) auto 0;
  color: var(--grey-600);
  text-decoration: underline;
  text-align: center;
}

.connect-status .row.pending {
  visibility: hidden;
}

.Connect .connect-status .pluggy.ui.button {
  /* prevent from being cover by the loading overlay */
  z-index: 1001;
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}

.Connect .connect-status .pluggy.ui.button .content {
  font-size: var(--text-font-size) !important;
}
/* loading "breathing" animation */
@keyframes progress-bar-loading {
  0% {
    box-shadow: 0 0 calc(var(--spacing) * 0.875)
      rgba(var(--connect-status-card-shadow-color), 0.2);
  }
  50% {
    box-shadow: 0 0 calc(var(--spacing) / 4)
      rgba(var(--connect-status-card-shadow-color), 0.1);
  }
  100% {
    box-shadow: 0 0 calc(var(--spacing) * 0.875)
      rgba(var(--connect-status-card-shadow-color), 0.2);
  }
}

.Connect .connect-status .connect-status-body {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(var(--spacing) * 0.75);
  margin-top: calc(var(--spacing) * 1.5);
  flex: 1;
  border-radius: calc(var(--spacing) / 4);
  box-shadow: 0 0 calc(var(--spacing) * 0.875)
    rgba(var(--connect-status-card-shadow-color), 0.1);
}

.Connect .connect-status.in-progress .connect-status-body {
  -webkit-animation: progress-bar-loading 3s ease infinite;
  animation: progress-bar-loading 3s ease infinite;
}

.Connect .connect-status.completed.connect-status-body {
  box-shadow: 0 0 calc(var(--spacing) * 0.875)
    rgba(var(--connect-status-card-shadow-color), 0.1);
}

.Connect .connect-status .connect-status-body .pluggy.connector-row {
  justify-content: center;
  margin-top: calc(var(--spacing) / 2);
}

.Connect .connect-status .Title {
  text-align: center;
  border-bottom: 1px solid var(--divider);
  margin: 0 var(--spacing) 0 var(--spacing);
  padding: calc(var(--spacing) * 1.75) 0 calc(var(--spacing) * 1.25) 0;
}

.Connect .connect-status .steps-grid {
  margin: 0;
}

.Connect .connect-status .accounts-alert-container {
  margin: 0;
  padding: 0 var(--spacing);
}

.Connect .connect-status .accounts-alert-container .ui.alert {
  margin: 0;
}
