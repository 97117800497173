.Connect .content .OpenFinancePermissions {
  position: absolute;

  background-color: var(--background);
  top: 0;
  left: 0;
  /* prevent from being cover by other components */
  z-index: 99999;
  height: 100%;
  width: 100%;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.Connect .content .OpenFinancePermissions .container {
  padding: calc(var(--spacing) * 3) var(--spacing) var(--spacing);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OpenFinancePermissions .open-finance-permissions-footer {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) / 2);
}

.OpenFinancePermissions .open-finance-valid-date {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: calc(var(--spacing) / 2);
}

.OpenFinancePermissions .open-finance-valid-date .calendar {
  min-width: calc(var(--spacing) * 1.5);
  min-height: calc(var(--spacing) * 1.5);
}

.OpenFinancePermissions .pluggy.ui.button {
  background-color: var(--primary);
  color: var(--white);
}
