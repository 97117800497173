.StepStatus {
  justify-content: flex-start;
}

.StepStatus .fourteen.wide.column {
  padding: 0 calc(var(--spacing) * 0.75) 0 0;

  font-size: var(--text-font-size-xs);
}

.StepStatus .right.aligned.two.wide.column {
  padding: 0 calc(var(--spacing) * 0.75) 0 0;

  width: unset !important;
}

.StepStatus .right.aligned.two.wide.column .check.icon {
  font-size: var(--text-font-size-s);
}
