.Connect .ConnectForm .MfaSelectRequestExpired {
  position: relative;
  flex: 1;
  padding-bottom: var(--spacing);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Connect .ConnectForm .MfaSelectRequestExpired .message {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40%;
}

.Connect .ConnectForm .MfaSelectRequestExpired .message p {
  text-align: center;
  width: calc(var(--spacing) * 18);
  font-size: var(--text-font-size);
}

.Connect .ConnectForm .MfaSelectRequestExpired .message img {
  width: calc(var(--spacing) * 6.812);
}
