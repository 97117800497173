.MissingBankForm {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.MissingBankForm > .ui.form {
  flex: 1;
}

.MissingBankForm .pluggy.ui.input.missing-bank-input {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.MissingBankForm .ui.form .pluggy.field.missing-bank-input {
  margin-bottom: 0;
}

/* remove space for errors and messages since we are not using it */
.MissingBankForm
  .pluggy.field
  .pluggy.ui.labeled.input.missing-bank-input
  ~ .text-container {
  display: none;
}

.MissingBankForm .search-results {
  box-shadow: var(--search-results-shadow);
  border-radius: calc(var(--spacing) * 0.25);
  padding: calc(var(--spacing) * 0.5);
  max-height: calc(var(--spacing) * 12.5);
  overflow-y: auto;
  background-color: var(--search-results-background);
}

.MissingBankForm .search-item {
  padding: var(--spacing);
  border-radius: calc(var(--spacing) * 0.25);
}

.MissingBankForm .search-item:hover,
.MissingBankForm .search-item:focus {
  outline: none;
  background-color: var(--search-results-item-hover);
}

.MissingBankForm .details {
  font-weight: 400;
  font-size: var(--text-font-size-s);
  line-height: var(--text-font-size);
}

.MissingBankForm .found-connector .header {
  font-weight: 500;
  font-size: var(--text-font-size-s);
  line-height: var(--text-font-size);
}

.MissingBankForm .found-connector .connector-row {
  padding: var(--spacing) 0;
}

.MissingBankForm .found-connector .connector-row .connector-logo {
  margin: 0 calc(var(--spacing) * 1.25) 0 calc(var(--spacing) * 0.5);
}

.MissingBankForm .found-connector .divider {
  margin: 0;
}

.MissingBankForm .open-finance-institutions {
  font-size: var(--text-font-size-xs);
}
