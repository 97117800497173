.DeviceAuthorizedStatus {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.DeviceAuthorizedStatus strong {
  font-weight: 500;
}

.DeviceAuthorizedStatus .message-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.DeviceAuthorizedStatus .title {
  font-family: var(--title-font-family);
  font-size: var(--text-font-size);
  font-weight: 500;
  line-height: calc(var(--text-font-size) * 1.375);

  margin-bottom: 0;
}

.DeviceAuthorizedStatus .message {
  margin-top: calc(var(--spacing) * 1.375);
  font-size: var(--text-font-size-s);
  line-height: calc(var(--spacing) * 1.025);
}

.DeviceAuthorizedStatus .action-container {
  display: flex;
  margin-top: auto;
}

.DeviceAuthorizedStatus .action-container .pluggy.ui.button .content {
  font-size: var(--text-font-size);
}

.DeviceAuthorizedStatus .action-container .pluggy.ui.button.primary {
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}
