.Connect > .content > .MissingBankPage {
  padding-top: 0;
}

.MissingBankPage .message {
  margin-bottom: calc(var(--spacing) * 1.25);
}

.MissingBankPage .ui.button {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Hack to prevent Semantic from overriding default loading btn positioning */
.MissingBankForm .ui.loading.loading.loading.loading.loading.loading.button {
  position: absolute;
}

.MissingBankPage .pluggy.ui.loading.button:after {
  /* faster animation since is a quick operation */
  animation-duration: 1.5s;
}
