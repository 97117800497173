.QRActionInstructions .title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  margin-bottom: var(--spacing);
}

.QRActionInstructions p,
.QRActionInstructions p:first-of-type {
  color: var(--text);
  margin: 0 0 var(--spacing);
}
