.GenialMfaInstructions {
  margin-top: calc(var(--spacing) * 2.5);
}

.GenialMfaInstructions > .genial-title {
  margin-bottom: 0;
}

.GenialMfaInstructions > .genial-title strong {
  font-weight: 500;
}

.GenialMfaInstructions > .genial-description {
  line-height: var(--text-font-size);
}
