.FAQOption {
  outline: none !important;
  padding: calc(var(--spacing) / 2) 0;
  font-family: var(--font-family);
}

.FAQOption .questionSection {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: none;

  padding: 0;
}

.FAQOption .icon {
  transition: transform 0.4s ease;
}

.FAQOption .rotate {
  transform: rotate(180deg);
}

.FAQOption .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FAQOption .question .question-title {
  max-width: 80%;
  font-size: var(--text-font-size);
  font-weight: 500;
  line-height: calc(var(--spacing) * 1.2);
  letter-spacing: 0px;
  text-align: left;
  color: var(--grey-900);
  margin: 0;
}

.FAQOption .answer {
  font-size: var(--text-font-size-s);
  font-family: var(--font-family);
  font-weight: 400;
  line-height: calc(var(--spacing) * 1.1875);
  letter-spacing: 0px;
  text-align: left;
  color: var(--grey-900);
  max-height: 0;
  overflow: hidden;
  margin-top: var(--spacing);

  transition: max-height 0.4s ease;
}

.FAQOption .answer div {
  margin-bottom: var(--spacing);
}
