.Connect .content.TermsPage {
  padding-bottom: var(--spacing);
  overflow-y: auto;

  padding-right: calc(var(--spacing) * 1.25);
  padding-left: calc(var(--spacing) * 1.25);
}

.Connect > .content .TermsPage {
  padding: 0;
  padding-bottom: var(--spacing);
  overflow-y: auto;
}

.Connect > .content .TermsPage::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: calc(var(--spacing) * 0.625);
  margin-left: calc(var(--spacing) * 1.25);
  margin-right: calc(var(--spacing) * 1.25);
  width: calc(100% - var(--spacing) * 2.75);
  height: calc(var(--spacing) * 1.5);
  background: var(--terms-page-gradient);
}
