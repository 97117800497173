.ConnectionSecurePopup {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  margin-bottom: calc(var(--spacing) * 0.75);

  text-decoration: underline;
  font-size: calc(var(--text-font-size) * 0.75);
  font-weight: 400;
  line-height: calc(var(--text-line-height) * 0.875);
  letter-spacing: 0px;
  color: var(--grey-600);
}

.ConnectionSecurePopup:focus {
  outline: none;
}

.ConnectionSecurePopup .ConnectionSecureIcon {
  margin-right: calc(var(--spacing) * 0.25);
}

.ConnectionSecurePopup-popup.ui.top.center.popup.transition.visible {
  padding: calc(var(--spacing) * 0.25) calc(var(--spacing) * 0.5);
  border-radius: calc(var(--spacing) * 0.25);
  border-color: transparent;
  box-shadow: 0px calc(var(--spacing) * 0.125) calc(var(--spacing) * 0.25)
    rgba(0, 0, 0, 0.15);

  color: var(--black);
  font-size: calc(var(--text-font-size) * 0.75);
  line-height: calc(var(--text-line-height) * 0.875);
  letter-spacing: 0px;
  text-align: left;
}

.ConnectionSecurePopup-popup::before {
  display: none;
}
