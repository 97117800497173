.Connect .Welcome {
  text-align: left;
}

.Connect .content.Welcome {
  overflow-y: auto;

  flex-grow: 1;
  flex-shrink: 0;
}

.Connect .content .Welcome {
  overflow-y: auto;
  padding-top: 0;
}

.Welcome .welcome-description {
  color: var(--title-text);
}

.Welcome .tip-row {
  padding-right: calc(var(--spacing) * 1.25);
  padding-left: calc(var(--spacing) * 1.25);
  margin-bottom: var(--spacing);
  font-size: var(--text-font-size);
  line-height: calc(var(--text-font-size) * 1.17);
  color: var(--text-label);
}

/* help centering bullets vertically when logo is hidden in ConnectHeader */
.ConnectHeader.logo-hidden + .Welcome .tip-row:first-child {
  margin-bottom: calc(2 * var(--spacing));
  margin-top: calc(2 * var(--spacing));
}

.ConnectHeader.logo-hidden + .Welcome .tip-row:last-child {
  margin-bottom: 0;
}

.Welcome .bullet-text {
  color: var(--text-welcome-bullet);
}

.Welcome .bullet-title {
  font-weight: 600;
  margin-bottom: calc(var(--spacing) / 2);
}

.Welcome .Title {
  font-size: var(--header-title-font-size);
  width: 100%;
  line-height: calc(var(--header-title-font-size) * 1.25);
  font-weight: 400;
  text-align: center;
  padding: 0 var(--spacing);
  color: var(--header-text);
}

/* help centering Welcome text vertically when logo is hidden in ConnectHeader */
.ConnectHeader.logo-hidden + .Welcome .Title {
  margin-top: calc(6 * var(--spacing));
}

.Welcome .Title > strong {
  font-weight: 600;
}

.Welcome .bullets-section {
  display: flex;
  flex: 1;
  justify-content: space-around;
  flex-direction: column;
}

.ui.grid > .row.tip-row {
  padding-bottom: 0;
  padding-top: var(--spacing);

  color: var(--text-status-page-row);
}

.ui.grid > .row.tip-row:last-child {
  padding-bottom: var(--spacing);
}

.ui.grid > .row > [class*='fourteen wide'].column {
  /* correction to reduce default 1rem padding */
  padding-left: calc(var(--spacing) / 2);
}

.Welcome .consent-section .pluggy.ui.button.primary {
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}

.Welcome .consent-section .pluggy.ui.button.primary .content {
  font-size: var(--text-font-size) !important;
}

.Welcome .TermsConsentText {
  margin-bottom: calc(var(--spacing) * 0.75);
}
