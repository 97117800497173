.DeviceUnauthorizedStatus {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.DeviceUnauthorizedStatus .device-authorization-message-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.DeviceUnauthorizedStatus
  .pluggy.connector-header
  .connector-row
  .connector-logo {
  margin-bottom: var(--spacing);
}

.DeviceUnauthorizedStatus .pluggy.alert.warning {
  margin: 0 0 var(--spacing) 0;
}

.DeviceUnauthorizedStatus .device-authorization-message-container .title {
  margin-bottom: 0;
  font-size: calc(var(--text-font-size));
  line-height: calc(var(--text-font-size) * 1.375);
  font-weight: 500;
}

.DeviceUnauthorizedStatus
  .pluggy.connector-header
  .connector-row
  .connector-logo {
  margin-bottom: var(--spacing);
}

.DeviceUnauthorizedStatus .pluggy.alert {
  margin: 0 0 var(--spacing) 0;
}

.DeviceUnauthorizedStatus .device-authorization-message-container p {
  line-height: var(--line-height);
  color: var(--grey-900);
}

.DeviceUnauthorizedStatus .subtitle {
  font-weight: 500;
  color: var(--text);
  font-size: calc(var(--text-font-size) * 0.85);
  margin: 0 0 calc(var(--spacing) * 0.75) 0;
}

.DeviceUnauthorizedStatus
  .device-authorization-message-container
  .device-authorization-details {
  margin-bottom: var(--spacing);
}

.DeviceUnauthorizedStatus
  .device-authorization-message-container
  .device-authorization-details-device {
  margin-left: calc(var(--spacing) * 0.5);
}

.DeviceUnauthorizedStatus .device-authorization-warning {
  display: flex;
  margin-top: auto;
  margin-bottom: calc(var(--spacing) * 0.25);
  font-size: var(--text-font-size-xs);
  color: var(--grey-600);
}

.DeviceUnauthorizedStatus .device-authorization-warning > span {
  margin-left: calc(var(--spacing) * 0.5);
  line-height: calc(var(--text-font-size));
}

.DeviceUnauthorizedStatus .action-container .pluggy.ui.button .content {
  font-size: var(--text-font-size);
}

.DeviceUnauthorizedStatus .action-container .pluggy.ui.button {
  /* necessary to fit large text */
  padding: calc(var(--spacing) * 0.25) 0;
}
