.Connect .content.ConnectorForm {
  overflow: auto;
}

.Connect .content .ConnectForm {
  padding-right: calc(var(--spacing) * 0.5);
  padding-bottom: 0;
  padding-top: 0;

  flex: 1;
  overflow-y: auto;
}

.Connect .ConnectForm .pluggy.alert {
  margin-top: 0;
}

.Connect
  .content.ConnectorForm
  .ConnectForm
  .pluggy.connector-header
  .pluggy.connector-row
  .connector-logo {
  margin: 0 calc(var(--spacing) * 1.25) 0 0;
  width: var(--spacing-x2);
  min-width: var(--spacing-x2);
  height: var(--spacing-x2);
  min-height: var(--spacing-x2);
}

.Connect
  .content.ConnectorForm
  .ConnectForm
  .pluggy.connector-header
  .connector-row {
  padding-right: var(--spacing);
}

.Connect
  .content.ConnectorForm
  .ConnectForm
  .pluggy.connector-header
  .pluggy.connector-row
  label {
  font-size: var(--text-font-size-s);
}

.ConnectForm .form {
  overflow-x: hidden;
  overflow-y: auto;
}

.ConnectForm .form-fields {
  flex: 1;
  margin-top: calc(var(--spacing) * 1.25);
}

.ConnectForm .form-fields.oauth-timeout {
  display: none;
}

.ConnectForm .form-fields .ui.error.message {
  margin-bottom: 0;
}

.ConnectForm .mfa-message {
  margin-top: calc(var(--spacing) * -0.25);
  margin-bottom: var(--spacing);
  line-height: normal;
}

.ConnectForm .mfa-image-container {
  min-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connector-form .form {
  height: 100%;
}

.ConnectForm .ui.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 0;
  padding-right: calc(var(--spacing) * 0.5);
  padding-bottom: var(--spacing);
  position: relative;
  height: 100%;
}

.form-fields {
  /* hardcode min width for fields to make space for scrollbar */
  min-width: calc(var(--spacing) * 17);
}

.form-footer {
  /* hardcode min width for fields to make space for scrollbar */
  min-width: calc(var(--spacing) * 18);
  /* prevent from being cover by the loading overlay */
  z-index: 1001;
}

.ConnectForm .ui.form .form-footer button[type='submit'] {
  /* prevent from being cover by the loading overlay */
  z-index: 1001;
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}

.ConnectForm .ui.form .form-footer button[type='submit'] .content {
  font-size: var(--text-font-size) !important;
}

.ConnectForm .ui.form .form-fields .ui.image.field-image {
  width: calc(var(--spacing) * 12.5);
  height: calc(var(--spacing) * 12.5);
  margin-top: calc(var(--spacing) * -0.5);
}

.ConnectForm .ui.form .form-fields .mfa-image-container.qr-login-container {
  width: 100%;
  height: calc(var(--spacing) * 17.75);
  border-radius: calc(var(--spacing) * 0.25);
  border: calc(var(--spacing) * 0.0625) solid var(--grey-100);
  margin-top: calc(var(--spacing) * 2.625);
}

.ConnectForm
  .ui.form
  .form-fields
  .mfa-image-container.qr-login-container
  .ui.image.field-image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  margin: 0;
}

.ConnectForm .ui.form .form-error.visible {
  height: auto;
}

.ConnectForm .ui.form .ui.primary.button {
  /* set margin-top 'auto' to move it to bottom */
  margin-top: auto;
}

.ConnectForm .mfa-message,
.ConnectForm .ui.checkbox,
.ConnectForm .ui.checkbox a,
.ConnectForm .ui.dropdown div.text,
.ConnectForm .ui.dropdown div.item {
  /* TODO this color should be on the theme CSS */
  color: var(--text-label);
}

.ConnectForm .mfa-image-container .MfaRemainingTimeMessage {
  margin-top: 0;
}

.ConnectForm .mfa-remaining-message {
  font-size: var(--text-font-size-xs);
  margin: 0 auto 0 auto;
  color: var(--text-mfa-remaining-time);
  white-space: nowrap;
}

.ConnectForm .form-footer {
  width: 100%;
  margin-top: calc(var(--spacing) * 0.75);
}

.Connect .form .form-error.assistive-text {
  color: var(--text);
}

.ConnectForm .field.pluggy.hidden {
  visibility: hidden;
}

/* Custom styles for MFA 2-step with QR image */
.ConnectForm
  .mfa-2step-login-form.has-mfa-image
  .pluggy.connector-header
  .connector-logo {
  margin-top: calc(var(--spacing) * 0.75);
  margin-bottom: calc(var(--spacing) * 0.75);
}

.ConnectForm .mfa-2step-login-form.has-mfa-image .mfa-message {
  font-size: calc(var(--text-font-size) * 0.8125);
  margin-bottom: calc(var(--spacing) * 0.75);
}

.ConnectForm .cooperative-selection-message {
  font-size: calc(var(--text-font-size) * 0.875);
  font-weight: 400;
  line-height: var(--text-font-size);
  letter-spacing: 0px;
}

.ConnectForm
  .mfa-2step-login-form.has-mfa-image
  .mfa-image-container
  .MfaRemainingTimeMessage
  .mfa-remaining-message {
  margin-bottom: 0;
}

.Connect .content .ConnectForm .loading-overlay {
  /* note: this was calculated manually */
  --loading-overlay-top-distance: calc(var(--spacing) * 3);

  position: absolute;
  content: '';
  top: var(--loading-overlay-top-distance);
  left: 0;
  width: 100%;
  /* substract --loading-overlay-top-distance to prevent overflow */
  height: calc(100% - var(--loading-overlay-top-distance));
  border-radius: 10px;

  background-color: var(--background);
  opacity: 0.7;
  z-index: 1000;
}

/* this is needed to cover the header without covering the "close" icon */
.Connect .content .ConnectForm .loading-overlay::after {
  position: absolute;
  content: '';
  left: 0;
  /* note that the loading overlay is positioned below the header
  so we are adding an "extension" of the loading overlay without
  overlapping and creating more opacity to cover the rest of the header */
  top: calc(-1 * var(--loading-overlay-top-distance));
  width: calc(100% - var(--loading-overlay-top-distance));
  height: var(--loading-overlay-top-distance);
  border-radius: 10px;

  background-color: var(--background);
  opacity: 0.7;
  z-index: 1000;
}

.Connect .content .ConnectForm .device-registration-notification-alert,
.Connect .content .ConnectForm .use-gov-br-credentials-notification-alert {
  margin-bottom: calc(var(--spacing) * 1.25);
}

.Connect .content .ConnectForm .link.help-link {
  display: block;
  font-size: var(--text-font-size-s);
  margin: calc(var(--spacing) / 3) 0;
}

.Connect .content.ConnectorForm .ConnectForm .file-upload-header {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: calc(var(--spacing) * 0.875);
  line-height: var(--spacing);
  margin-bottom: calc(var(--spacing) * 1.25);
  margin-top: 0;
}

.Connect .content.ConnectorForm .ConnectForm .pluggy.RadioSelect {
  /* necesary since accounts selection overflows full height */
  max-height: calc(var(--spacing) * 17.3125);
  overflow-y: auto;
}

.Connect .ConnectForm .input-container {
  position: relative;
}

@media (hover: none) {
  .Connect .pluggy.connector-list .pluggy.connector-row:hover {
    /* workaround to avoid hover effect on mobile devices. https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices */
    background-color: unset;
  }
}

.hcaptcha-iframe-container {
  margin: 0 auto;
  width: 60%;
}
