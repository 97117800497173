.GoBackIcon {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(var(--spacing) * 1.25);
  height: calc(var(--spacing) * 1.25);
  left: var(--spacing);
  top: var(--spacing);
  z-index: 10;
  filter: brightness(var(--icons-filter-brightness));
}

.GoBackIcon svg {
  width: var(--spacing);
  height: var(--spacing);
}
