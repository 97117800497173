.WelcomePageSkeletonLoader {
  display: flex;
  flex-direction: column;

  padding: var(--spacing);
  height: 100%;
  flex: 1;
}

.WelcomePageSkeletonLoader .header {
  width: 100%;
  margin-top: calc(var(--spacing) * 4.375);
  padding: 0 var(--spacing);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: calc(var(--spacing) * 1.5);
}

.WelcomePageSkeletonLoader .header .logo {
  width: calc(var(--spacing) * 7.5);
  height: calc(var(--spacing) * 1.875);
}

.WelcomePageSkeletonLoader .header .title {
  width: calc(var(--spacing) * 14.5);
  height: calc(var(--spacing) * 1.25);
}

.WelcomePageSkeletonLoader .header .title:not(:first-of-type) {
  width: calc(var(--spacing) * 11.06);
  margin: calc(var(--spacing) * 0.3125) auto 0;
}

.WelcomePageSkeletonLoader .bullet-sections {
  width: 100%;
  padding: 0 var(--spacing);
  margin-top: calc(var(--spacing) * 2);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing);
}

.WelcomePageSkeletonLoader .bullet-section {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: calc(var(--spacing) * 0.25);
}

.WelcomePageSkeletonLoader .bullet-section .title {
  width: calc(var(--spacing) * 7.0625);
  height: calc(var(--spacing) * 0.625);
}

.WelcomePageSkeletonLoader .bullet-section .text {
  width: calc(var(--spacing) * 15.5);
  height: calc(var(--spacing) * 0.625);
  margin-top: calc(var(--spacing) * 0.625);
}

.WelcomePageSkeletonLoader .footer {
  width: 100%;
  margin-top: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.WelcomePageSkeletonLoader .footer .terms {
  width: 100%;
  height: calc(var(--spacing) * 0.625);
}

.WelcomePageSkeletonLoader .footer .terms:not(:first-of-type) {
  margin-top: calc(var(--spacing) * 0.625);
}

.WelcomePageSkeletonLoader .footer .button {
  width: 100%;
  margin-top: var(--spacing);
  height: calc(var(--spacing) * 2.875);
  border-radius: calc(var(--spacing) * 0.625);
}
