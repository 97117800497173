.pluggy.connector-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

/* necessary override connector row styles in order to use the component as header */
.pluggy.connector-header .connector-row {
  width: 100%;
  padding: 0;
}

.pluggy.connector-header .connector-row:hover {
  background-color: transparent;
  cursor: default;
}

.pluggy.connector-header .connector-row .connector-logo {
  margin: 20px 16px 20px 0;
}

.pluggy.connector-header .connector-row .connector-tag {
  margin-right: auto;
  margin-left: 16px;
}
