.ui.error.message {
  color: var(--error);
  background: var(--error-background);
  border: 0;
  box-shadow: unset;
  text-align: left;
  flex-shrink: 0;
}

.ui.error.message .header,
.ui.error.message .content,
.ui.error.message .content p {
  color: var(--error);
}

.ui.message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  border-radius: 4px;
}

.ui.message p {
  line-height: var(--line-height);
}
