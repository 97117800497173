.AuthorizedDevicesLimitReached {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.AuthorizedDevicesLimitReached .Title {
  margin-bottom: 0;
}

.AuthorizedDevicesLimitReached .actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;

  gap: calc(var(--spacing) * 0.75);
}

.AuthorizedDevicesLimitReached .message {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 16px;
}
