.ConnectorsListSkeletonLoader {
  display: flex;
  flex-direction: column;

  padding: var(--spacing);
  height: 100%;
  flex: 1;
}

.ConnectorsListSkeletonLoader .logo {
  width: calc(var(--spacing) * 6);
  height: calc(var(--spacing) * 1.25);
  margin: var(--spacing) auto 0;
}

.ConnectorsListSkeletonLoader .title {
  width: calc(var(--spacing) * 11.375);
  height: calc(var(--spacing) * 1.375);
  margin-top: calc(var(--spacing) * 1.375);
}

.ConnectorsListSkeletonLoader .search-bar {
  width: 100%;
  height: calc(var(--spacing) * 2);
  margin-top: calc(var(--spacing) * 1.375);
}

.ConnectorsListSkeletonLoader .connectors-type {
  width: 100vw;
  height: calc(var(--spacing) * 2.45);
  margin-left: calc(var(--spacing) * -1);
  margin-top: var(--spacing);
  border-radius: 0;
}

.connectors-list-container {
  margin-top: calc(var(--spacing) * 1.375);
}

.ConnectorsListSkeletonLoader .connectors-list-item .connector-data-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ConnectorsListSkeletonLoader .connectors-list-item .connectors-list-item-logo {
  width: calc(var(--spacing) * 2);
  height: calc(var(--spacing) * 2);
  border-radius: 100%;
  margin: var(--spacing) calc(var(--spacing) * 1.25) var(--spacing) 0;
}

.ConnectorsListSkeletonLoader .connectors-list-item .connectors-list-item-name {
  width: calc(var(--spacing) * 4.125);
  height: var(--spacing);
}

.ConnectorsListSkeletonLoader .connectors-list-item-line {
  width: 100%;
  height: calc(var(--spacing) * 0.0625);
}
