:root {
  /* fonts */
  --font-family: Work Sans, -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-monospace: 'Inconsolata', 'Consolas', 'Droid Sans Mono', 'Monaco',
    'Courier New', monospace;
  --font-size: 0.875rem;

  --title-font-family: 'Poppins';
  --title-font-size: 1.125rem;
  --title-font-weight: 500;
  --title-font-stretch: normal;
  --title-font-style: normal;
  --title-line-height: normal;
  --title-letter-spacing: normal;

  --font-weight: normal;
  --font-stretch: normal;
  --font-style: normal;
  --line-height: normal;
  --letter-spacing: normal;

  --form-input-font-size: 0.75rem;
  --form-input-line-height: 0.75rem;
  --form-input-error-font-size: 1rem;

  /* relative values */
  --spacing: 16px;
  --text-font-size: var(--spacing);

  /* spacing variations */
  --spacing-x2: calc(var(--spacing) * 2);

  /* fonts */
  --header-title-font-size: calc(var(--text-font-size) * 1.25);
  --title-font-size: calc(var(--text-font-size) * 1.125);
  --text-font-size-s: calc(var(--text-font-size) * 0.875);
  --text-font-size-xs: calc(var(--text-font-size) * 0.75);

  --primary-button: var(--primary);
  --input-focused-border: var(--primary) !important;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background: var(--background);
  color: var(--text);

  font-weight: var(--font-weight);
  font-stretch: var(--font-stretch);
  font-style: var(--font-style);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
}

body ::-webkit-scrollbar {
  width: 0.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  color: var(--title-text);
}

p {
  color: var(--text);
}

.Connect a,
.link {
  cursor: pointer;
  font-weight: normal;
  transition: text-shadow 0.3s;
}

a.link.disabled,
button.link.disabled,
a.link.disabled:hover,
button.link.disabled:hover {
  color: var(--primary-button-disabled);
  cursor: not-allowed;
  text-decoration-line: initial;
}

.text {
  color: var(--text);
}

.text:hover {
  color: var(--text);
}

.secondary-text {
  color: var(--secondary-text);
}

.pointer {
  cursor: pointer;
}

.ui.menu,
.ui.checkbox,
.ui.error,
.ui.message,
.ui.input,
.ui.form,
button.link {
  /* Override Semantic UI component default font sizes */
  font-size: var(--font-size);
  font-family: var(--font-family);

  /* Override Semantic UI component default font props */
  font-weight: var(--font-weight);
  font-stretch: var(--font-stretch);
  font-style: var(--font-style);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
}

.skeleton {
  border-radius: calc(var(--spacing) * 0.25);
}
