html,
body,
#root,
.Connect {
  /* source:
   * https://stackoverflow.com/questions/90178/make-a-div-fill-the-height-of-the-remaining-screen-space
   */
  height: 100%;
  margin: 0;
  /* necessary to fix ios border-radius bug */
  border-radius: 10px;
  overflow-y: auto;
}

.ui.dimmer .Connect .ui.loader:before {
  border-color: var(--secondary);
}

.ui.dimmer .Connect .ui.loader:after {
  border-color: var(--primary) transparent transparent;
}

.Connect {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.Connect.is-modal {
  border: 0;
}

.Connect > .content {
  flex: 1 1 auto;
}

.Connect > .content.close-modal-open {
  position: relative;
  flex: 1 1 auto;
}

.Connect > .content > div {
  padding-top: calc(var(--spacing) * 1.5);
  padding-bottom: var(--spacing);
  padding-right: var(--spacing);
  padding-left: var(--spacing);
  display: flex;
  height: 100%;
  flex-direction: column;
}

.Connect .ui.button {
  width: 100%;
  height: calc(var(--spacing) * 2.875);
  line-height: 0;
  font-weight: 500;
}

.Connect .description .ui.header {
  margin-top: 10px;
}

.Connect .description {
  min-height: 360px;
}

.Connect .footer {
  /* footer */
  flex: 0 0 calc(var(--spacing) * 1.875);
  width: 100%;
}

.Connect .footer.demo-application {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--red-500);

  color: var(--white);
  font-size: var(--text-font-size-xs);
  font-weight: 400;
  line-height: var(--text-font-size-s);
  letter-spacing: 0px;
}

.Connect .credentials {
  background: rgba(0, 0, 0, 0.7);
  color: var(--white);
  line-height: calc(var(--text-font-size) * 1.875);

  text-align: center;
  font-size: calc(var(--text-font-size) * 0.75);
}

.Connect .error-container > img {
  margin-top: 1rem;
  filter: brightness(var(--invalid-credentials-image-brightness));
}

.Connect .action-link-container {
  text-align: center;
  padding-top: var(--spacing);
}

.Connect .action-link-container a {
  font-size: var(--text-font-size-s);
}

.Connect .connect-title {
  padding: 0 var(--spacing);
}
