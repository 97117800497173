.OauthConnectForm .oauth-message p {
  line-height: var(--line-height);
}

.OauthConnectForm .form-footer .open-finance-terms {
  font-size: calc(var(--spacing) * 0.75);
  margin-bottom: calc(var(--spacing) * 0.75);
}

.OauthConnectForm .form-footer .open-finance-terms .terms {
  color: var(--blue-400);
}

.OauthConnectForm .form-footer .open-finance-terms .terms:hover {
  text-decoration: underline;
  cursor: pointer;
}

.OauthConnectForm .open-finance-see-permissions-button {
  font-size: calc(var(--spacing) * 0.75);
  margin-top: calc(var(--spacing) * 0.75);
  color: var(--text);
  text-decoration: underline;
}

.OauthConnectForm .open-finance-see-permissions-button:hover {
  cursor: pointer;
}
