.ui.input > input:active ~ * .Icon path,
.ui.input > input:focus ~ * .Icon path,
.Icon path {
  transition: fill 0.3s ease;
  fill: var(--icon-active);
}

.ui.input > input:hover:not(:focus) ~ * .Icon path,
.Icon:hover path {
  fill: var(--icon-hover);
}

.link.icon:hover {
  color: var(--icon-hover);
}

.link.icon {
  color: var(--icon-active);
}

.Icon.disabled path {
  fill: var(--icon-disabled);
}

.Icon {
  height: 16px;
  width: 16px;
}
