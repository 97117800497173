:root {
  /* global */
  --background: #ffffff;
  --danger: #ffa900;
  --error: #ff0000;
  --divider: #e3e9ef;
  --error-background: #ffdede;

  /* colors */
  --primary: #d54258;
  --primary-border: none;
  --primary-hover: #b72b40;
  --primary-hover-border: none;
  --primary-disabled: rgba(255, 255, 255, 0.16);
  --primary-disabled-border: none;

  --secondary: rgba(0, 0, 0, 0);
  --secondary-hover: rgba(233, 183, 191, 0.2);
  --secondary-disabled: rgba(0, 0, 0, 0);
  --secondary-disabled-border: solid 1px rgba(255, 255, 255, 0.12);

  /* text */
  --text: var(--grey-800);
  --secondary-text: #138572;
  --title-text: #1f130f;
  --header-text: #0c0028;
  --text-on-primary: #fff;
  --text-on-primary-disabled: rgb(255, 255, 255);
  --text-on-secondary: #d54258;
  --text-welcome-bullet: #3d3d3d;
  --text-on-secondary-disabled: rgba(255, 255, 255, 0.38);
  --text-label: #111111;
  --text-consent-text: var(--grey-600);
  --text-mfa-remaining-time: #3d3d3d;
  --text-mfa-timer: #3d3d3d;
  --text-status-page-row: #303030;
  --text-progress-bar-label: rgba(0, 0, 0, 0.87);

  /* link */
  --text-link: #1a73e8;
  --text-link-hover: #1a73e8;
  --connectors-text-link: #1a73e8;

  /* Icon */
  --icon-active: #292828;
  --icon-hover: #6d6d6d;
  --icon-disabled: #b4b4b4;

  /* Input */
  --input-disabled: #f5f5f5;

  /* Search */
  --search-results-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  --search-results-item-hover: #e6e6e6;
  --search-results-background: var(--background);

  /* Icons */
  --icons-filter-brightness: 1;

  /* Terms Page */
  --terms-page-gradient: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.8) 56%,
    rgba(255, 255, 255, 0.3) 100%
  );

  /* Invalid Credentials */
  --invalid-credentials-image-brightness: 1;

  /* Progress Bar */
  --progress-bar-background: rgba(0, 0, 0, 0.1);

  /* Connect Status */
  --connect-status-card-shadow-color: 0, 0, 0;

  /* sandbox selector */
  --sandbox-selector-suggestion-result-hover: var(--grey-50);
}
