.ConnectTokenExpiredError {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ConnectTokenExpiredError .message {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ConnectTokenExpiredError .message p {
  text-align: center;
  font-size: var(--text-font-size);
  margin-top: calc(var(--spacing) * 1.25);
}

.ConnectTokenExpiredError .message img {
  width: calc(var(--spacing) * 6.812);
}
