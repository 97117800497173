.Title {
  text-align: left;
  font-family: var(--title-font-family);
  font-size: var(--text-font-size);
  line-height: calc(var(--text-font-size) * 1.37);
  font-weight: var(--title-font-weight);
  color: var(--text);

  margin: 0 0 var(--spacing) 0;
}
