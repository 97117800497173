.CooperativeFlowSelection {
  position: relative;
}

.Connect
  .ConnectorForm
  .ui.form.cooperative-flow-select
  .form-fields
  .CooperativeFlowSelection
  .pluggy.field {
  /* necessary since the filed has a harcoded height and creates overflow */
  /* note: using important to override Connect overrides using !important */
  height: 100% !important;
  margin-bottom: 0;
  margin-top: 0;
}

.CooperativeFlowSelection > .pluggy.field .RadioSelect {
  max-height: calc(var(--spacing) * 18.3125);
  overflow-y: auto;
}
