.Mfa2StepInstructions {
  font-size: var(--text-font-size-s);
  line-height: calc(var(--text-font-size-s) * 1.2);
}

.Mfa2StepInstructions .ui.button.pluggy.link,
.Mfa2StepInstructions .ui.button.pluggy.link .content {
  display: inline;
  font-weight: 400;
}
