.Connect .content.PrivacyPolicyPage {
  overflow-y: auto;
  padding: 0 calc(var(--spacing) * 1.25);
}

.Connect > .content .PrivacyPolicyPage {
  padding: 0;

  overflow-y: auto;
}

.Connect > .content .PrivacyPolicyPage::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(var(--spacing) * 1.5);
  margin-left: calc(var(--spacing) * 0.5);
  margin-right: calc(var(--spacing) * 0.5);
  width: calc(100% - var(--spacing) * 1.25);
  background: var(--terms-page-gradient);
}
