.Connect .content .CloseConfirmation {
  position: absolute;

  background-color: var(--background);
  top: 0;
  /* prevent from being cover by other components */
  z-index: 99999;
  height: 100%;
  width: 100%;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.Connect .content .CloseConfirmation .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Connect .content .CloseConfirmation .container .pluggy.ui.button {
  justify-self: flex-end;
}

.Connect .content .CloseConfirmation .Title {
  margin-bottom: var(--spacing);
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.CloseConfirmation .text-container .message {
  font-size: var(--text-font-size-s);
  line-height: var(--text-font-size);
  margin-bottom: calc(var(--spacing) * 1.25);
}

.CloseConfirmation .action-container {
  display: flex;
  margin-top: auto;
  flex-direction: column;
}

.CloseConfirmation .action-container .pluggy.ui.button:first-of-type {
  margin-bottom: calc(var(--spacing) * 0.625);
}

.CloseConfirmation .action-container .pluggy.ui.button {
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}

.CloseConfirmation .action-container .pluggy.ui.button .content {
  font-size: var(--text-font-size);
}
