.Connect .Dropdown.pluggy .dropdown__control {
  min-height: calc(var(--spacing) * 2.5);
}

.Connect .Dropdown.pluggy .dropdown__indicator {
  margin-left: calc(var(--spacing) * 0.625);
}

.Connect .Dropdown.pluggy .dropdown__indicator svg.DropdownIcon {
  width: calc(var(--spacing) * 0.875);
  height: calc(var(--spacing) * 0.875);
}

.Connect .Dropdown.pluggy .dropdown__menu-list .dropdown__option label,
.Connect .Dropdown.pluggy .dropdown__control .dropdown__single-value,
.Connect .Dropdown.pluggy .dropdown__control .placeholder {
  font-size: var(--text-font-size-s);
  line-height: calc(var(--text-font-size) * 1.5);
}

.Connect .Dropdown.pluggy .dropdown__control .dropdown__single-value {
  margin: 0 calc(var(--spacing) * 0.125);
}

.Connect .Dropdown.pluggy .dropdown__menu-list {
  padding: calc(var(--spacing) * 0.5);
}

.Connect .Dropdown.pluggy .dropdown__menu-list .dropdown__option {
  padding: calc(var(--spacing) * 0.5) calc(var(--spacing) * 0.75);
}
