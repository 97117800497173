.WaitUserAction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.WaitUserAction .instructions-introduction,
.WaitUserAction .instructions,
.WaitUserAction .subtitle {
  font-family: var(--font-family);
  color: var(--grey-900);
  font-size: calc(var(--text-font-size) * 0.875);
  line-height: var(--text-font-size);
  letter-spacing: 0px;
  text-align: left;

  margin: 0 0 var(--spacing) 0;
}

.WaitUserAction .instructions {
  margin: 0;
}

.WaitUserAction .subtitle {
  font-weight: 500;
}

.WaitUserAction .subtitle:first-of-type {
  margin-bottom: calc(var(--spacing) * 0.75);
}

.WaitUserAction .device-section {
  margin-top: calc(var(--spacing) * 1.75);
}

.WaitUserAction .device-list {
  padding-left: var(--spacing);
}

.WaitUserAction .instructions-step:last-of-type {
  margin-top: calc(var(--spacing) * 0.75);
  font-size: calc(var(--text-font-size) * 0.75);
}

.WaitUserAction .timer {
  margin: var(--spacing) auto 0;
  font-size: calc(var(--text-font-size) * 0.75);
  font-weight: 400;
  line-height: calc(var(--text-font-size) * 0.875);
  letter-spacing: 0px;
  text-align: left;
  color: var(--text-mfa-timer);
}

.WaitUserAction .timer .time {
  font-weight: 500;
}
