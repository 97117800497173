body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* prevent scroll bar from showing */
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 100%;
}

.title {
  font-family: var(--title-font-family);
  font-size: var(--title-font-size);
  font-weight: var(--title-font-weight);
  font-stretch: var(--title-font-stretch);
  font-style: var(--title-font-style);
  line-height: var(--title-line-height);
  letter-spacing: var(--title-letter-spacing);
  color: var(--title-text);
}
