.Connect .content .ErrorStatus .ui.grid {
  height: 100%;
}

.Connect .content .ErrorStatus {
  justify-content: space-between;

  position: relative;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: var(--spacing);
}

.Connect .content .ErrorStatus .ui.grid {
  flex-direction: column;

  padding-right: var(--spacing);
  padding-left: var(--spacing);
}

.Connect .content .ErrorStatus .ui.grid > .row {
  flex-basis: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.Connect .content .ErrorStatus .row.error-image {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Connect .content .ErrorStatus .row.error-image img {
  max-height: 120px;
}

.Connect .content .ErrorStatus .row.error-message {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

.Connect .content .ErrorStatus .row.error-message p {
  font-size: var(--text-font-size);
  color: var(--text-label);
}

.Connect .content .ErrorStatus .row.error-message .message {
  flex: 1;
}

.Connect .content .ErrorStatus .row.error-message .error-code {
  background: hsla(0deg, 0%, 60%, 10%);
  border-radius: 4px;
  padding: calc(var(--spacing) * 0.5);
  margin: 0;
  color: var(--error);
  font-size: calc(var(--spacing) * 0.625);
}

.Connect .content .ErrorStatus .error-message h4 {
  margin: auto;
  text-align: center;
}

.Connect .content .ErrorStatus .action-container {
  width: 100%;
  left: 0;
}

.Connect .content .ErrorStatus .action-container {
  bottom: calc(var(--spacing) * 1.5);
}

.Connect .content .ErrorStatus .action-container > button {
  height: calc(var(--spacing) * 2.875);
  margin-top: calc(var(--spacing) * 0.75);
}
