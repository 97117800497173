.SandboxCredentialSuggestion {
  position: absolute;
  width: 100%;
  max-height: calc(var(--spacing) * 11.5);
  top: calc(var(--spacing) * 2.8125);
  left: 0;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: calc(var(--spacing) * 0.5);
  border-radius: calc(var(--spacing) * 0.25);
  overflow-y: auto;
  z-index: 100000000;
  background-color: var(--background);
}

.SandboxCredentialSuggestion .result {
  cursor: pointer;
  width: 100%;

  font-family: var(--font-family);
  font-size: calc(var(--text-font-size) * 0.875);
  font-weight: 400;
  line-height: calc(var(--spacing) * 1.5);
  text-align: left;

  border-radius: calc(var(--spacing) * 0.25);
  padding: calc(var(--spacing) * 0.25) calc(var(--spacing) * 0.5);
}

.SandboxCredentialSuggestion .result {
  background-color: transparent;
  border: none;
}

.SandboxCredentialSuggestion .result:not(:last-of-type) {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.SandboxCredentialSuggestion .result:hover,
.SandboxCredentialSuggestion .result:focus {
  background-color: var(--sandbox-selector-suggestion-result-hover);
  outline: none;
}
