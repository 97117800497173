.ui.loader:before {
  border: 0;
}

.ui.loader:after {
  border-color: var(--primary) transparent transparent;
}

.loader-container {
  position: relative;
  min-height: 360px;
  height: 100%;
}
