.ConnectFormSkeletonLoader {
  display: flex;
  flex-direction: column;

  padding: var(--spacing);
  height: 100%;
  flex: 1;
}

.ConnectFormSkeletonLoader .logo {
  width: calc(var(--spacing) * 6);
  height: calc(var(--spacing) * 1.25);
  margin: var(--spacing) auto 0;
}

.ConnectFormSkeletonLoader .title {
  width: calc(var(--spacing) * 11.375);
  height: calc(var(--spacing) * 1.375);
  margin-top: calc(var(--spacing) * 1.375);
}

.ConnectFormSkeletonLoader .connector-data-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ConnectFormSkeletonLoader .connector-data-container .connector-logo {
  width: calc(var(--spacing) * 2);
  height: calc(var(--spacing) * 2);
  border-radius: 100%;
  margin: var(--spacing) calc(var(--spacing) * 1.25) var(--spacing) 0;
}

.ConnectFormSkeletonLoader .connector-data-container .connector-name {
  width: calc(var(--spacing) * 5.125);
  height: var(--spacing);
}

.ConnectFormSkeletonLoader .form {
  margin-top: calc(var(--spacing) * 1.25);
}

.ConnectFormSkeletonLoader .form .form-input {
  width: 100%;
  height: calc(var(--spacing) * 2.5);
}

.ConnectFormSkeletonLoader .form .form-input:last-of-type {
  margin-top: calc(var(--spacing) * 1.75);
}

.ConnectFormSkeletonLoader .footer {
  margin-top: auto;
}

.ConnectFormSkeletonLoader .footer .button {
  width: 100%;
  height: calc(var(--spacing) * 2.875);
}

.ConnectFormSkeletonLoader .footer .reset-password-link {
  margin: var(--spacing) auto 0;
  width: calc(var(--spacing) * 6.375);
  height: var(--spacing);
}
