.Connect .ConnectorsList,
.Connect .ConnectorsList .connector-search {
  overflow-y: auto;
  padding-top: 0;
}

.ConnectorsList .Title {
  margin-bottom: calc(var(--spacing) * 1.25);
}

/* remove button override */
.Connect
  .ConnectorsList
  .connector-list
  .connector-rows
  button.ui.button.pluggy.link {
  height: unset;
  font-weight: 400;
}

.Connect
  .ConnectorsList
  .connector-list
  .connector-rows
  button.ui.button.pluggy.link {
  height: calc(var(--spacing) * 2.5);
}

/* pluggy search-box */
.ConnectorsList .connector-search .pluggy.connector-list .pluggy.search-box {
  height: calc(var(--spacing) * 2.5);
}

/* searchbox input */

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .pluggy.search-box
  .ui.input {
  height: calc(var(--spacing) * 2.5);
}

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .pluggy.search-box
  .pluggy.search-box-input {
  height: var(--spacing-x2);
  font-size: var(--text-font-size-s);
  padding: calc(var(--spacing) * 0.375) calc(var(--spacing) * 0.5);
}

/* searchbox icon */
.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .pluggy.search-box
  .search-box-icon {
  width: calc(var(--spacing) * 1.25);
  height: calc(var(--spacing) * 1.25);
  margin-left: calc((var(--spacing) * 1.75) * -1);
  margin-top: calc(var(--spacing) * 0.375);
}

/* tabs */
.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .connector-tabs
  .pluggy.tab {
  font-size: var(--text-font-size-s);
  padding: calc(var(--spacing) * 0.7188);
}

/* List */
.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .connector-container
  .pluggy
  .connector-row {
  padding-left: var(--spacing);
}

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .connector-container
  .pluggy.connector-row
  label {
  font-size: var(--text-font-size-s);
}

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .connector-container
  .pluggy.connector-row
  .connector-logo {
  margin: calc(var(--spacing) * 1.25);
  width: var(--spacing-x2);
  min-width: var(--spacing-x2);
  height: var(--spacing-x2);
  min-height: var(--spacing-x2);
}

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .connector-container
  .pluggy.connector-row
  .connector-logo
  img {
  width: var(--spacing-x2);
  min-width: var(--spacing-x2);
  height: var(--spacing-x2);
  min-height: var(--spacing-x2);
}

/* button */
.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .missing-bank-button {
  padding-top: var(--spacing);
}

.Connect
  .ConnectorsList
  .connector-search
  .pluggy.connector-list
  .missing-bank-button
  .pluggy.button
  .content {
  font-size: var(--text-font-size-s);
}

.Connect .ConnectorsList .connector-search .connector-container .empty-result {
  margin-top: calc(var(--spacing) * 6);
  font-size: var(--text-font-size-s);
}
