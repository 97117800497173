.OpenFinanceFAQButton {
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  margin-bottom: calc(var(--spacing) / 2);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: var(--text-font-size-s);
  color: var(--grey-600);
  text-decoration: underline;
  font-family: var(--font-family);
  gap: calc(var(--spacing) / 4);
}
