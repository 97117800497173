.MfaRemainingTimeMessage {
  text-align: center;
  margin-top: var(--spacing-x2);
}

.MfaRemainingTimeMessage .mfa-timer {
  font-size: var(--text-font-size-s);
  color: var(--text-mfa-timer);
  margin-bottom: calc(var(--spacing) * 0.75);
  margin-left: calc(var(--spacing) * 0.25);
}

.Connect .MfaRemainingTimeMessage .ui.button.pluggy.link {
  font-weight: 400;
}
