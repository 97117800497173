/* needed to override Connect styles */
.Connect > .content > .SandboxSelectionList {
  padding-top: 0;
  justify-content: space-between;
}

.SandboxSelectionList .pluggy.connector-row {
  margin-bottom: calc(var(--spacing) * 1.25);
}

.SandboxSelectionList .pluggy.connector-row .connector-logo {
  margin: 0 calc(var(--spacing) * 1.25) 0;
  margin-left: 0;
  width: var(--spacing-x2);
  min-width: var(--spacing-x2);
  height: var(--spacing-x2);
  min-height: var(--spacing-x2);
}

.SandboxSelectionList .connector-row {
  padding-right: var(--spacing);
}

.SandboxSelectionList .pluggy.connector-row label {
  font-size: var(--text-font-size-s);
}

.SandboxSelectionList .instruction {
  margin-bottom: calc(var(--spacing) * 0.75);
}

.SandboxSelectionList .flow-selection-header .pluggy.Dropdown {
  margin-bottom: calc(var(--spacing) * 1.5);
}

.SandboxSelectionList .flow-selection-header .link {
  font-size: var(--text-font-size-s);
  line-height: var(--text-font-size);
}

.SandboxSelectionList .pluggy.ui.button.primary.medium {
  background-color: var(--pink-400);
  border-color: var(--pink-400);
  height: unset;
  padding: calc(var(--spacing) * 0.75) var(--spacing);
}

.SandboxSelectionList .pluggy.ui.button.primary.medium:hover {
  background-color: var(--pink-500);
  border-color: var(--pink-500);
}

.SandboxSelectionList .pluggy.ui.button.primary.medium .content {
  font-size: var(--text-font-size);
}

.SandboxSelectionList .Dropdown.pluggy .dropdown__control--is-focused,
.SandboxSelectionList .Dropdown.pluggy:hover .dropdown__control--is-focused,
.SandboxSelectionList
  .dropdown__control.dropdown__control--is-focused.dropdown__control--menu-is-open.css-t3ipsp-control {
  border-color: var(--pink-400);
}
